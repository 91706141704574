// import Loading from "components/loading/Loading";
import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Loader } from "../components";
import {
  HOME_ROUTE,
  ABOUT_US_ROUTE,
  CONTACT_ROUTE,
  VACANCIES_ROUTE,
  SINGLE_VACANCY_ROUTE,
  FAQ_ROUTE,
  STARTUPS_ROUTE,
  DEVOPS_ROUTE,
  MOBILE_APP_ROUTE,
  HOW_WE_WORK_ROUTE,
  DEDICATED_TEAMS_ROUTE,
  WEBSITE_DEVELOPMENT_ROUTE,
  PORTFOLIO_ROUTE,
  UI_ROUTE,
  SOFTWARE_QA_ROUTE,
  ASSOCIATE_ROUTE,
  APPLICATIONMAINTAIN_ROUTE,
  INTERNSHIP_ROUTE,
  DOTNET_ROUTE,
  BIG_DATA_ROUTE,
  BUSINESS_ANALYSIS_ROUTE,
  PRODUCT_DESIGN_ROUTE,
  SOFTWARE_ARCHITECTURE_ROUTE,
  BUSINESS_PROCESS_AUTOMATION_ROUTE,
  APP_DEVELOPMENT_ROUTE,
  ENTERPRISE_INFORMATION_SYSTEMS_ROUTE,
  IMPERO_GLANCE_ROUTE,
  VISION_ROUTE,
  LEADERSHIP_ROUTE,
} from "./CONSTANTS";
const Home = lazy(() => import("../views/landingPage/LandingPage"));
const About = lazy(() => import("../views/aboutUs/About"));
const Contact = lazy(() => import("../views/CONTACT/Contact"));
const Careers = lazy(() => import("../views/careers/Careers"));
const JobPage = lazy(() => import("../views/jobPage/JobPage"));
const Faq = lazy(() => import("../views/faq/Faq"));
const devops = lazy(() => import("../views/devops/Devops"));
const aplicationMaintain = lazy(() =>
  import("../views/aplicationMaintain/AplicationMaintain")
);
const StartUps = lazy(() => import("../views/startUps/StartUps"));
const MobileAppPage = lazy(() =>
  import("../views/mobileAppPage/MobileAppPage")
);
const HowWeWork = lazy(() => import("../views/howWeWork/HowWeWork"));
const DedicatedTeams = lazy(() =>
  import("../views/dedicatedTeams/DedicatedTeams")
);
const WebsiteDevelopment = lazy(() => import("../views/webDev/WebDev"));
const Portfolio = lazy(() => import("../views/portfolio"));

const UiPage = lazy(() => import("../views/uiux/Ui"));
const SoftwareQA = lazy(() => import("../views/softwareQA/SoftwareQA"));
const Associate = lazy(() => import("../views/associate/Associate"));
const Internship = lazy(() => import("../views/internship/Internship"));
const Dotnet = lazy(() => import("../views/dotnet/Dotnet"));
const BigData = lazy(() => import("../views/bigData/BigData"));
const BusinessAnalysis = lazy(() =>
  import("../views/businessAnalysis/BusinessAnalysis")
);
const ProductDesign = lazy(() =>
  import("../views/productDesign/ProductDesign")
);
const SoftwareArch = lazy(() => import("../views/softwareArch/SoftwareArch"));
const BusinessProcess = lazy(() =>
  import("../views/businessProcess/BusinessProcess")
);
const AppDevelopment = lazy(() =>
  import("../views/appDevelopment/AppDevelopment")
);
const EnterpriseInfo = lazy(() =>
  import("../views/enterpriseInfo/EnterpriseInfo")
);
const ImperoGlance = lazy(() => import("../views/imperoGlance/ImperoGlance"));
const Vision = lazy(() => import("../views/vision/Vision"));
const Leadership = lazy(() => import("../views/leadership/Leadership"));

const RouterConfig = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={HOME_ROUTE} component={Home} />
        <Route path={UI_ROUTE} component={UiPage} />
        <Route exact path={ABOUT_US_ROUTE} component={About} />
        <Route exact path={VACANCIES_ROUTE} component={Careers} />
        <Route path={SINGLE_VACANCY_ROUTE} component={JobPage} />
        <Route path={CONTACT_ROUTE} component={Contact} />
        <Route path={FAQ_ROUTE} component={Faq} />
        <Route path={DEVOPS_ROUTE} component={devops} />
        <Route
          path={APPLICATIONMAINTAIN_ROUTE}
          component={aplicationMaintain}
        />
        <Route path={STARTUPS_ROUTE} component={StartUps} />
        <Route path={MOBILE_APP_ROUTE} component={MobileAppPage} />
        <Route path={HOW_WE_WORK_ROUTE} component={HowWeWork} />
        <Route path={DEDICATED_TEAMS_ROUTE} component={DedicatedTeams} />
        <Route path={ASSOCIATE_ROUTE} component={Associate} />
        <Route
          path={WEBSITE_DEVELOPMENT_ROUTE}
          component={WebsiteDevelopment}
        />
        <Route path={PORTFOLIO_ROUTE} component={Portfolio} />
        <Route path={SOFTWARE_QA_ROUTE} component={SoftwareQA} />
        <Route path={INTERNSHIP_ROUTE} component={Internship} />
        <Route path={DOTNET_ROUTE} component={Dotnet} />
        <Route path={BIG_DATA_ROUTE} component={BigData} />
        <Route path={BUSINESS_ANALYSIS_ROUTE} component={BusinessAnalysis} />
        <Route path={PRODUCT_DESIGN_ROUTE} component={ProductDesign} />
        <Route path={SOFTWARE_ARCHITECTURE_ROUTE} component={SoftwareArch} />
        <Route
          path={BUSINESS_PROCESS_AUTOMATION_ROUTE}
          component={BusinessProcess}
        />
        <Route path={APP_DEVELOPMENT_ROUTE} component={AppDevelopment} />
        <Route
          path={ENTERPRISE_INFORMATION_SYSTEMS_ROUTE}
          component={EnterpriseInfo}
        />
        <Route path={IMPERO_GLANCE_ROUTE} component={ImperoGlance} />
        <Route path={VISION_ROUTE} component={Vision} />
        <Route path={LEADERSHIP_ROUTE} component={Leadership} />
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
