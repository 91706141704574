import "./tableRow.scss";

const TableRow = ({ number, amount, month, year }) => {
  return (
    <div className="tableRow">
      <div className="tableRow__container">
        <div className="tableRow__number">{number}</div>
        <div className="tableRow__amount">{amount}</div>
        <div className="tableRow__month">{month}</div>
        <div className="tableRow__year">{year}</div>
      </div>
    </div>
  );
};

export default TableRow;
