import TableRow from "./component/tableRow/TableRow";
import "./salaryTable.scss";

const SalaryTable = ({ data }) => {
  const mappedLists = data.map((item, key) => {
    return <TableRow key={key} {...item} />;
  });

  return (
    <div className="salaryTable">
      <div className="salaryTable__container container">
        <TableRow number="S/No" amount="Amount" month="Month" year="Year" />
        {mappedLists}
      </div>
    </div>
  );
};

export default SalaryTable;
