export const HOME_ROUTE = "/";
export const ABOUT_US_ROUTE = "/aboutus";
export const CONTACT_ROUTE = "/contact";
export const MOBILE_APP_ROUTE = "/mobile-app";
export const HOW_WE_WORK_ROUTE = "/how-we-work";
export const DEDICATED_TEAMS_ROUTE = "/dedicated-teams";
export const WEBSITE_DEVELOPMENT_ROUTE = "/website-development";
export const VACANCIES_ROUTE = "/vacancies";
export const SINGLE_VACANCY_ROUTE = "/vacancies/:id";
export const FAQ_ROUTE = "/faq";
export const STARTUPS_ROUTE = "/startups";
export const APPLICATIONMAINTAIN_ROUTE = "/aplicationmaintain";
export const PORTFOLIO_ROUTE = "/portfolio";
export const DEVOPS_ROUTE = "/devops";
export const UI_ROUTE = "/design-services";
export const SOFTWARE_QA_ROUTE = "/software-qa";
export const ASSOCIATE_ROUTE = "/associate";
export const INTERNSHIP_ROUTE = "/internship";
export const DOTNET_ROUTE = "/dotnet-developers";
export const BIG_DATA_ROUTE = "/big-data-consulting-services";
export const BUSINESS_ANALYSIS_ROUTE = "/business-analysis";
export const PRODUCT_DESIGN_ROUTE = "/product-design";
export const SOFTWARE_ARCHITECTURE_ROUTE = "/software-architecture";
export const BUSINESS_PROCESS_AUTOMATION_ROUTE = "/business-process-automation";
export const APP_DEVELOPMENT_ROUTE = "/app-development";
export const ENTERPRISE_INFORMATION_SYSTEMS_ROUTE =
  "/enterprise-information-systems";
export const IMPERO_GLANCE_ROUTE = "/imperotechne-at-a-glance";
export const VISION_ROUTE = "/values";
export const LEADERSHIP_ROUTE = "/leadership";
