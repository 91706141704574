import "./App.scss";
import RouterConfig from "./navigations/RouterConfig";

const App = () => {
  return (
    <div className="App">
      <RouterConfig />
    </div>
  );
};

export default App;
