import LinkTag from "../linkTag/LinkTag";
import "./generalCardImg.scss";

const GeneralCardImg = ({ header, paragraph, btnText, btnLink, src }) => {
  return (
    <div className="generalCardImg">
      <div className="generalCardImg__bg">
        <img src={src} alt="" />
      </div>
      <div className="generalCardImg__container container">
        <h3 className="generalCardImg__header header3">{header}</h3>
        <p className="generalCardImg__paragraph">{paragraph}</p>
        {btnText && (
          <div className="generalCardImg__btnDiv">
            <LinkTag to={btnLink} label={btnText} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralCardImg;
