import "./generalCard.scss";

const GeneralCard = ({
  header,
  paragraph,
  hover,
  icon,
  iconUp,
  shadow,
  width,
  bluebg,
  column,
  whitetextcolor,
  margin,
}) => {
  return (
    <div
      className={`generalCard ${hover && "hover"} ${column && "column"} ${
        shadow && "diplayshadow"
      } ${width && "width"} ${bluebg && "blue"} ${
        whitetextcolor && "whitetext"
      } ${margin && "margin"}`}
    >
      <div className={`generalCard__container  `}>
        <h4 className={`generalCard__header header4 ${iconUp && "iconUp"}`}>
          {icon && <span className="generalCard__headerIcon">{icon}</span>}
          <span className={`generalCard__headerText `}>
            {/* rango */}
            {header}
          </span>
        </h4>
        <p className="generalCard__paragraph">{paragraph}</p>
      </div>
    </div>
  );
};

export default GeneralCard;
