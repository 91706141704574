import { Link } from "react-router-dom";
import { imperoLogo } from "../../assets";
import { demo } from "../../assets";
import LinkTag from "../linkTag/LinkTag";
import Submenu, { Submenu1 } from "../submenu/Submenu";
import { FiChevronDown } from "react-icons/fi";
import "./header.scss";
import { Hamburger } from "..";
import { useState } from "react";

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  // const [submenu, setSubmenu] = useState({
  //   company: false,
  //   services: false,
  //   careers: false,
  // });

  // const handleSubmenu = (arg) => {
  //   setSubmenu((prev) => {
  //     let keys = Object.keys(prev);
  //     let newState = { ...prev };

  //     keys.forEach((key) => {
  //       if (key === arg) {
  //         newState[arg] = !newState[arg];
  //       } else {
  //         newState[key] = false;
  //       }
  //     });

  //     return newState;
  //   });
  // };

  const handleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className="header">
      <div className="header__container container">
        <div className="header__brand">
          <Link to="/">
            <img src={imperoLogo} alt="" className="header__brandImg" />
          </Link>
        </div>
        <nav className="header__nav">
          <div className="header__navlink">
            <div className="header__navlinkText">
              <span className="header__navlinkTextSpan">About Us</span>
              <FiChevronDown className="header__navlinkArrow" />
            </div>
            <div className="header__submenu">
              <Submenu1
                src={demo}
                paragraph="Full-cycle custom software development company with focus on
            FinTech, HealthTech, InsurTech, EduTech solutions."
                header="Company"
                submenuData={[
                  // { label: "About", to: "/aboutus" },
                  {
                    label: "Impero Techne at a Glance",
                    to: "/imperotechne-at-a-glance",
                  },
                  {
                    label: "Mission, Vision, Values and Covenants",
                    to: "/values",
                  },
                  { label: "How We Work", to: "/how-we-work" },
                  // { label: "Leadership", to: "/leadership" },
                  // { label: "FAQ", to: "/faq" },
                ]}
              />
            </div>
          </div>
          <div className="header__navlink">
            <div className="header__navlinkText">
              <span className="header__navlinkTextSpan">Services</span>
              <FiChevronDown className="header__navlinkArrow" />
            </div>
            <div className="header__submenu">
              <Submenu
                submenuData={[
                  {
                    navText: "Enterprise Technology Consulting",
                    dropdown: [
                      { label: "Business Analysis", to: "/business-analysis" },
                      {
                        label: "Product (UI/UX) Design",
                        to: "/product-design",
                      },
                      {
                        label: "Software Architecture",
                        to: "/software-architecture",
                      },
                    ],
                  },
                  {
                    navText: "Enterprise Software Development",
                    dropdown: [
                      {
                        label: "Business Process Automation",
                        to: "/business-process-automation",
                      },
                      {
                        label: "App Development (Desktop, Web, Api & Mobile)",
                        to: "/app-development",
                      },
                    ],
                  },
                  {
                    navText: "Enterprise Architecture",
                    dropdown: [
                      {
                        label:
                          "Enterprise Information Systems and Cloud Computing",
                        to: "/enterprise-information-systems",
                      },
                      {
                        label:
                          "IT network infrastructure Management/Support Services",
                        to: "#",
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
          <div className="header__navlink">
            <div className="header__navlinkText">
              <span className="header__navlinkTextSpan">Careers</span>
              <FiChevronDown className="header__navlinkArrow" />
            </div>
            <div className="header__submenu">
              <Submenu1
                src={demo}
                paragraph="Full-cycle custom software development company with focus on
            FinTech, HealthTech, InsurTech, EduTech solutions."
                header="Products"
                submenuData={[
                  { label: "Vacancies", to: "/vacancies" },
                  { label: "Associate", to: "/associate" },
                  { label: "Internship", to: "/internship" },
                ]}
              />
            </div>
          </div>
          <LinkTag to="/contact" label="Contact Us" btn />
        </nav>
        <Hamburger toggle={showNav} toggleFunc={handleNav} />
        <div className={`header__dropdown ${showNav ? "active" : ""}`}>
          <nav className="header__nav">
            <div className="header__navlink">
              <div className="header__navlinkText">
                <span className="header__navlinkTextSpan">About Us</span>
                <FiChevronDown className="header__navlinkArrow" />
              </div>
              <div className="header__submenu">
                <Submenu1
                  src={demo}
                  paragraph="Full-cycle custom software development company with focus on
            FinTech, HealthTech, InsurTech, EduTech solutions."
                  header="Company"
                  submenuData={[
                    // { label: "About", to: "/aboutus" },
                    {
                      label: "Impero Techne at a Glance",
                      to: "/imperotechne-at-a-glance",
                    },
                    {
                      label: "Mission, Vision, Values and Covenants",
                      to: "/values",
                    },
                    { label: "How We Work", to: "/how-we-work" },
                    // { label: "Leadership", to: "/leadership" },
                    // { label: "FAQ", to: "/faq" },
                  ]}
                />
              </div>
            </div>
            <div className="header__navlink">
              <div className="header__navlinkText">
                <span className="header__navlinkTextSpan">Services</span>
                <FiChevronDown className="header__navlinkArrow" />
              </div>
              <div className="header__submenu">
                <Submenu
                  submenuData={[
                    {
                      navText: "Enterprise Technology Consulting",
                      dropdown: [
                        {
                          label: "Business Analysis",
                          to: "/business-analysis",
                        },
                        {
                          label: "Product (UI/UX) Design",
                          to: "/product-design",
                        },
                        {
                          label: "Software Architecture",
                          to: "/software-architecture",
                        },
                      ],
                    },
                    {
                      navText: "Enterprise Software Development",
                      dropdown: [
                        {
                          label: "Business Process Automation",
                          to: "/business-process-automation",
                        },
                        {
                          label: "App Development (Desktop, Web, Api & Mobile)",
                          to: "/app-development",
                        },
                      ],
                    },
                    {
                      navText: "Enterprise Architecture",
                      dropdown: [
                        {
                          label:
                            "Enterprise Information Systems and Cloud Computing",
                          to: "/enterprise-information-systems",
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
            <div className="header__navlink">
              <div className="header__navlinkText">
                <span className="header__navlinkTextSpan">Careers</span>
                <FiChevronDown className="header__navlinkArrow" />
              </div>
              <div className="header__submenu">
                <Submenu1
                  src={demo}
                  paragraph="Full-cycle custom software development company with focus on
            FinTech, HealthTech, InsurTech, EduTech solutions."
                  header="Products"
                  submenuData={[
                    { label: "Vacancies", to: "/vacancies" },
                    { label: "Associate", to: "/associate" },
                    { label: "Internship", to: "/internship" },
                  ]}
                />
              </div>
            </div>
            <LinkTag to="/contact" label="Contact Us" btn />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
