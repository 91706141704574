import { imperoLogo } from "../../assets";
import {
  FaInstagram,
  FaLinkedinIn,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";
import LinkTag from "../linkTag/LinkTag";
import "./footer.scss";
import SocialIcons from "../socialIcons/SocialIcons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__body">
          <div className="footer__content">
            <div className="footer__brandDiv">
              <img src={imperoLogo} alt="Impero Techne" />
            </div>
            <div className="footer__social">
              <h4 className="footer__header header4">Follow Us</h4>
              <div className="footer__socialIcons">
                <SocialIcons to="https://www.instagram.com/imperotechne/">
                  <FaInstagram />
                </SocialIcons>

                <SocialIcons to="https://www.linkedin.com/company/imperotechne/">
                  <FaLinkedinIn />
                </SocialIcons>

                <SocialIcons to="https://www.facebook.com/ImperoTechne">
                  <FaFacebookF />
                </SocialIcons>

                <SocialIcons to="https://youtube.com/channel/UCseaQtzN1GGjypOYZaU-x4Q">
                  <FaYoutube />
                </SocialIcons>
              </div>
            </div>
          </div>
          <div className="footer__content">
            <h4 className="footer__header header4">Explore</h4>
            <LinkTag label="About Us" to="/imperotechne-at-a-glance" />
            {/* <LinkTag label="Vacancies" to="/vacancies" /> */}
            {/* <LinkTag label="FAQ" to="/faq" /> */}
            {/* <LinkTag label="Portfolio" to="/portfolio" /> */}
            <LinkTag label="How We Work" to="/how-we-work" />
          </div>
          <div className="footer__content">
            <h4 className="footer__header header4">Services</h4>
            {/* <LinkTag label="Mobile Development" to="/mobile-app" /> */}
            <LinkTag label="Product (UI/UX) Design" to="/product-design" />
            <LinkTag label="Business Analysis" to="/business-analysis" />
            <LinkTag
              label="Software Architecture"
              to="/software-architecture"
            />
            <LinkTag label="App Development" to="/app-development" />
            <LinkTag
              label="Enterprise Information Systems"
              to="/enterprise-information-systems"
            />
          </div>
          <div className="footer__content">
            <div className="footer__contentChild">
              <h4 className="footer__header header4">Careers</h4>
              <LinkTag label="Vacancies" to="/vacancies" />
              <LinkTag label="Associate" to="/associate" />
              <LinkTag label="Internship" to="/internship" />
            </div>
          </div>
          <div className="footer__content">
            <div className="footer__contentChild">
              <h4 className="footer__header header4">Contacts</h4>
              {/* <a href="tel:+2347025000382" className="linkTag">
                +234 702 500 0382 (Sales)
              </a>
              <a href="tel:+2347025000381" className="linkTag">
                +234 702 500 0381 (Corporate)
              </a> */}
              {/* <a href="mailto:infotech@imperotechne.com " className="linkTag">
                infotech@imperotechne.com
              </a> */}
              <a href="mailto:info@imperotechne.com" className="linkTag">
                info@imperotechne.com
              </a>
              <a href="tel:+2347025000382" className="linkTag">
                +234 702 500 0382
              </a>
              <a href="tel:+2347025000381" className="linkTag">
                +234 702 500 0381
              </a>
            </div>
            <div className="footer__contentChild">
              <h4 className="footer__header header4">Offices</h4>
              <span className="linkTag">
                {" "}
                Plot 58A, Block 1, Omorinre Johnson Street, Lekki Phase One,
                Lagos, Nigeria.
              </span>
            </div>
            <div className="footer__btnDiv">
              <LinkTag btn label="Get in touch" to="/contact" />
            </div>
          </div>
          {/* <div className="footer__content">
            <div className="footer__contentChild">
              <h4 className="footer__header header4">Offices</h4>
              <span className="linkTag">
                {" "}
                Plot 58A, Block 1, Omorinre Johnson Street, Lekki Phase One,
                Lagos, Nigeria.
              </span>
            </div>
            <div className="footer__btnDiv">
              <LinkTag btn label="Get in touch" to="/contact" />
            </div>
          </div> */}
        </div>
        <div className="footer__footer">
          <div className="footer__policy">
            <LinkTag label="Privacy Policy" to="/" />
          </div>
          <p className="footer__copyright">
            &copy; 2019 - 2021 Impero Techne. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
