import "./step.scss";

const Step = ({ number, text }) => {
  return (
    <div className="step">
      <span className="step__stepNumber">{number}</span>
      <span className="step__stepName header4">
        <span className="step__stepNameText">{text}</span>
      </span>
    </div>
  );
};

export default Step;
