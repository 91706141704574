import NavLinkTag from "../navLinkTag/NavLinkTag";
import Dropdown from "./components/dropdown/Dropdown";
import "./submenu.scss";

// const Dropdown = ({ data }) => {
//   console.log(data);
//   return (
//     <>
//       <div>
//         <span>{data.navText}</span>
//         <span>
//           <FiChevronDown />
//         </span>
//       </div>
//       <div>
//         {data.dropdown.map((item, key) => {
//           return <NavLinkTag label={item.label} key={key} to={item.to} />;
//         })}
//       </div>
//     </>
//   );
// };

const Submenu = ({ submenuData = [] }) => {
  const mapped = submenuData.map((data, key) => {
    return <Dropdown data={data} key={key} />;
  });

  return (
    <div className="submenu">
      <div className="submenu__container">
        <div className="submenu__links">{mapped}</div>
      </div>
    </div>
  );
};

export const Submenu1 = ({ header, paragraph, src, submenuData = [] }) => {
  const mapped = submenuData.map((data, key) => {
    return <NavLinkTag label={data.label} key={key} to={data.to} />;
  });

  return (
    <div className="submenu">
      <div className="submenu__container">
        <div className="submenu__links">{mapped}</div>
      </div>
    </div>
  );
};

export default Submenu;
