import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./linkTag.scss";

const LinkTag = ({ to = "/", label, externalLink, btn, white, outline }) => {
  return (
    <Link
      className={`linkTag ${btn && "btn"} 
       ${white && "white"} ${outline && "outline"}`}
      to={externalLink ? { pathname: externalLink } : to}
      target={externalLink ? "_blank" : "_self"}
    >
      {label}
    </Link>
  );
};

LinkTag.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  btn: PropTypes.any,
  outline: PropTypes.any,
};

export default LinkTag;
