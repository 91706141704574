import { FiChevronDown } from "react-icons/fi";
import { NavLinkTag } from "../../..";
import "./dropdown.scss";

const Dropdown = ({ data }) => {
  return (
    <div className="dropdown">
      <div className="dropdown__nav">
        <span className="dropdown__text">{data.navText}</span>
        <span className="dropdown__icon">
          <FiChevronDown />
        </span>
      </div>
      <div className="dropdown__submenu">
        {data.dropdown.map((item, key) => {
          return <NavLinkTag label={item.label} key={key} to={item.to} />;
        })}
      </div>
    </div>
  );
};

export default Dropdown;
