import { useState } from "react";
import Form from "../form/Form";
import { formData } from "./formData";
import axios from "axios";
import "./contactSection.scss";
import { useErrorAlert, useSuccessAlert } from "../alert/AlertContext";

const ContactSection = ({ contactPage }) => {
  const [contactForm, setContactForm] = useState({
    name: "",
    company: "",
    email: "",
    phoneNo: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState(null);
  // const [loading, setLoading] = useState(false);

  const dispatchSuccessMsg = useSuccessAlert();
  const dispatchFailureMsg = useErrorAlert();

  // useEffect(() => {
  //   if (formStatus !== null) {
  //     success("Message Successfully Sent.");
  //   }
  // }, [formStatus, success]);

  console.log(formStatus);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);

    const body = {
      name: contactForm.name,
      companyName: contactForm.company,
      email: contactForm.email,
      phoneNumber: contactForm.phoneNo,
      message: contactForm.message,
    };

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(
        // "http://localhost:5000/api/impero-contact",
        "https://eming-mailer.herokuapp.com/api/impero-contact",
        body,
        config
      );

      // console.log(res);
      setFormStatus(res.data);

      if (res) {
        // setLoading(false);
        dispatchSuccessMsg({ message: "Message Successfully Sent." });
        setContactForm((prev) => ({
          ...prev,
          name: "",
          company: "",
          email: "",
          phoneNo: "",
          message: "",
        }));
      }

      // if (res.data) {
      //   success("Message Successfully Sent.");
      // }
    } catch (error) {
      console.log(error);
      dispatchFailureMsg({ message: "Message Not Sent." });
      // failure("Message Not Sent.");
      Promise.reject(error);
    }
  };

  return (
    <div className={`contactSection ${contactPage && "contact"}`}>
      <div className="contactSection__container container">
        <h2 className="contactSection__header sectionHeader">Contact Form</h2>
        <Form
          onSubmit={(e) => handleSubmit(e)}
          state={contactForm}
          stateFunc={setContactForm}
          data={formData}
        />
      </div>
    </div>
  );
};

export default ContactSection;
